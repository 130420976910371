<template>
  <span>{{ value ? $t('Yes') : $t('No') }}</span>
</template>

<script>
export default {
  name: 'TemplateBoolean',
  props: {
    value: Boolean,
  },
};
</script>
