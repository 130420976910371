






































import { Vue, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import Layout from '@/layouts/LayoutDefault.vue';
import TemplateBoolean from '@/templates/TemplateBoolean.vue';
import TemplateDate from '@/templates/TemplateDate.vue';

import Firm from '@models/firm/Firm';
import FirmUpdate from '@models/firm/FirmUpdate';
import { ActionInterface } from '@/types/breadcrumb';
import User from '@models/user/User';

@Component({
  components: {
    Layout,
    TemplateBoolean,
    TemplateDate,
  },
})
export default class MyDeclarationListView extends Vue {
  @Getter('currentFirm') firm!: Firm;

  get declarations() {
    return FirmUpdate.query().with('createdBy').all();
  }

  get actions(): Array<ActionInterface> {
    return this.isGranted('my-employees', 1) ? [
      {
        label: 'Effectuer une déclaration',
        route: 'my-declarations-new',
      },
    ] : [];
  }

  beforeDestroy() {
    FirmUpdate.deleteAll();
    User.deleteAll();
  }
}
